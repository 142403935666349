export const Eco = {
    name: "Verduurzamings API",
    docs: 'https://docs.altum.ai/ecovalue-api',
    description: "Met de Verduurzamings API creëert Altum AI een uniek inzicht in duurzaamheidsmaatregelen en daarmee samenhangende vastgoedwaardestijging voor woningen in Nederland. De bron voor het berekenen van de Ecovalue van een woning is het zogenaamde Automated Valuation Model (AVM) van Altum AI. Dit model werkt met meer dan 150 variabelen per huis die in realtime worden bijgewerkt. Houd rekening met bekende variabelen zoals bouwjaar, binnenoppervlak, volume, perceeloppervlak. De Ecovalue is de deltawaarde van een woning na toepassing van specifieke duurzaamheidsmaatregelen in samenhang met een Energielabel verhoging. De Ecovalue API zorgt ook voor de nodige maatregelen, gecombineerd met de investeringen en besparing in euro’s en CO2 per maatregel.",
    link: "/sandbox/ecovalue",
    methods: ["POST"],
    example: {
        post: {
            request: {
                headers: `Content-Type: application/json\nx-api-key: m2ipzWVV3e9yPU9TduqpY4oZTbcEHCGj31GLVLYB`,
                body: `{\n"postcode": "2771DS",\n"housenumber": "87",\n"data": {\n\t"addition": "string",\n\t"lock_measures": {"lock": ["wall_insulation"]},\n\t"max_investment": 20000,\n\t"gas_usage": 3000,\n\t"target_label": "A",\n\t"inner_surface_area": 130,\n\t"inhabitants": 3,\n\t"replace_boiler": true,\n\t"heat_pump_allowed": true,\n\t"search_criteria": 1,\n\t"search_options": 2,\n\t"house-type": 1,\n\t"natural_gas": 0,\n\t"solar_panels_surface": 1,\n\t"watt_panels": true,\n\t"wall_insulation": 1,\n\t"roof_insulation": 1,\n\t"floor_insulation": 1,\n\t"living_room_windows": 1,\n\t"bedroom_windows": 1,\n\t"installation": 4,\n\t"shower": 0,\n\t"ventilation": 0,\n\t"solar_panels": 10
    }
}`
            },
            response: {
                headers: `date: Fri, 23 Jul 2021 07:45:58 GMT\ncontent-type: application/json\ncontent-length: 2086\nx-amzn-requestid: e64e944d-f480-4bfe-b4ed-be1344749e84\naccess-control-allow-origin: *\nx-amz-apigw-id: C6cEnHwpjoEFsNQ=\nx-amzn-trace-id: Root=1-60fa73b6-0db4ce48115f815a362830c6`,
                body: `{
"address": {
    "house_number": 87,
    "post_code": "2771DS",
    "addition": null,
    "street": "Distellaan",
    "city": "Aerdenhout",
    "location": {
    "lat": "52.3628210709531",
    "lon": "4.60227719634112"
    }
},
"building": {
    "build_year": "1928",
    "inner_surface": "170.0",
    "type": "2 onder 1 kap"
},
"label": {
    "current": "G",
    "potential": "A"
},
"provided_label": {
    "temporary": "G",
    "definitive": "null"
},
"meta": "null",
"energyindex": {
    "current": "2.98",
    "potential": "1.01"
},
"ELG": {
    "current": "21.95",
    "potential": "10.51"
},
"comfortscore": {
    "current": "1",
    "potential": "3"
},
"CO2": {
    "current": "9555",
    "potential": "2444"
},
"financial": {
    "total_investment": "30590.0",
    "total_saving": "3115.0",
    "months_to_pay_off": "117",
    "eco_value": "18017.0",
    "savings_monthly": "260",
    "energy_cost_monthly": "354",
    "loan": {
    "monthly_payment": "126.67047729756744"
    }
},
"usage": {
    "gas": "4505",
    "energy": "3201"
},
"measures": {
    "wall_insulation": {
    "before": {
        "desc": "Geen",
        "value": "0"
    },
    "after": {
        "desc": "Matig/na-isolatie",
        "value": "1"
    },
    "investment": "2973.0",
    "saving": "788.0",
    "co2_reduce": "1798"
    },
    "floor_insulation": {
    "before": {
        "desc": "Geen",
        "value": "0"
    },
    "after": {
        "desc": "Geen",
        "value": "0"
    },
    "investment": "0.0",
    "saving": "0.0",
    "co2_reduce": "0"
    },
    "roof_insulation": {
    "before": {
        "desc": "Geen",
        "value": "0"
    },
    "after": {
        "desc": "Zeer goed",
        "value": "3"
    },
    "investment": "14192.0",
    "saving": "1158.0",
    "co2_reduce": "2643"
    },
    "living_room_windows": {
    "before": {
        "desc": "Dubbel glas",
        "value": 1
    },
    "after": {
        "desc": "HR++ glas",
        "value": "2"
    },
    "investment": "2927.0",
    "saving": "74.0",
    "co2_reduce": "169"
    },
    "bedroom_windows": {
    "before": {
        "desc": "Enkel glas",
        "value": "0"
    },
    "after": {
        "desc": "HR++ glas",
        "value": "2"
    },
    "investment": "2318.0",
    "saving": "212.0",
    "co2_reduce": "484"
    },
    "solar_panels": {
    "before": {
        "desc": "0",
        "value": "0"
    },
    "after": {
        "desc": "26",
        "value": "26"
    },
    "investment": "8180.0",
    "saving": "802.0",
    "co2_reduce": "1833"
    },
    "instalation": {
    "before": {
        "desc": "HR-combi",
        "value": "4"
    },
    "after": {
        "desc": "HR-combi",
        "value": "4"
    },
    "investment": "0.0",
    "saving": "0.0",
    "co2_reduce": "0"
    },
    "shower": {
    "before": {
        "desc": "Geen Douche WTW",
        "value": "0"
    },
    "after": {
        "desc": "Geen Douche WTW",
        "value": "0"
    },
    "investment": "0.0",
    "saving": "0.0",
    "co2_reduce": "0"
    }
},
"response_meta": "null"
}`
            }
        }
    }
}
export const AVM = {
    name: "Woningwaarde API",
    docs: 'https://docs.altum.ai/avm-api',
    description: "Zowel huiseigenaren als potentiële kopers kunnen de Woningwaarde API gebruiken om de huidige marktwaarde van een huis te weten te komen. Deze API gebruikt gegevens uit openbare registers, die maandelijks worden bijgewerkt met nieuw geregistreerde verkoopprijzen Altum AI biedt een eenvoudig te gebruiken, nauwkeurige en snelle Woningwaarde API die de geschatte marktprijs voor een huis berekent.",
    link: "/sandbox/avm",
    methods: ["POST"],
    example: {
        post: {
            request: {
                headers: `Content-Type: application/json
x-api-key: m2ipzWVV3e9yPU9TduqpY4oZTbcEHCGj31GLVLYB`,
                body: `{
    "postcode" : "1234AB",
    "housenumber" : "5",
    "houseaddition" : "",
    "valuationdate" : "20200326",
    "image": 1,
    "buildyear": 2000,
    "innersurfacearea": 150,
    "outersurfacearea": 100,
    "volume": 500,
    "energylabel": "B",
    "woz": 1
}`
            },
            response: {
                headers:`Date: Thu, 22 Jul 2021 22:42:49 GMT
Content-Type: application/json
Content-Length: 485
Connection: keep-alive
x-amzn-RequestId: 91360fd6-3395-4eee-8e97-344c21e3214b
Access-Control-Allow-Origin: *
x-amz-apigw-id: C5MghEFYDoEF8Qg=
X-Amzn-Trace-Id: Root=1-60f9f469-2aa17bb94c2ce24366a3fe1c
            `,
                body: `{
    "Output": {
        "BagID": "3558951451532213",
        "PostCode": "1234AB",
        "HouseNumber": "5",
        "HouseAddition": null,
        "City": "Amsterdam",
        "Street": "Bootstraat",
        "HouseType": "Vrijstaande woning",
        "BuildYear": "2021",
        "InnerSurfaceArea": "265",
        "OuterSurfaceArea": "1363",
        "Volume": "1250",
        "EnergyLabel": "A",
        "Longitude": "4.4000000000000000",
        "Latitude": "52.00000000000000",
        "Rooms": "7",
        "Image": "\"null\"",
        "ValuationDate": "20200326",
        "PriceEstimation": "1277424",
        "Confidence": "90% Confidence Interval is 1006355-1529716."
    }
}`
            }
        }
    }
}
export const woz = {
    name: "WOZ waarde API",
    docs: 'https://docs.altum.ai/apis/woz-api',
    description: "Elke gemeente bepaalt jaarlijks de waarde van woningen, bedrijfspanden, winkels en onbebouwde kavels. Deze waarde heet de WOZ-waarde (Waardering Onroerende Zaken). De gemeente gebruikt de WOZ-waarde om de hoogte van de belastingen te bepalen. Met WOZ API creëert Altum AI een unieke en eenvoudige modus operandi voor het ophalen van WOZ-waarden voor woningen en eigendommen in Nederland. De bron van de WOZ-waarden is de officiële WOZ-website. Dit model levert waarden op voor woningen met BAGID of Huisadres zolang de WOZ-waarden op de WOZ-website staan. De WOZ API geeft in zijn reactie ook andere details, zoals het bouwjaar, coördinaten, doel van eigendom, enz.",
    link: "/sandbox/woz",
    methods: ["POST", "GET"],
    example: {
        post: {
            request: {
                headers: `Content-Type: application/json
x-api-key: m2ipzWVV3e9yPU9TduqpY4oZTbcEHCGj31GLVLYB`,
                body: `{
    "postcode": "1222HA",
    "housenumber": "64",
    "addition": "B" 
}`
            },
            response: {
                headers: `access-control-allow-origin: *
content-length: 611
content-type: application/json
date: Mon, 26 Jul 2021 21:50:18 GMT
x-amz-apigw-id: DGQkMHJIDoEFeYg=
x-amzn-requestid: d96e8578-f52a-49ce-b927-9cc11e2dabef
x-amzn-trace-id: Root=1-60ff2e1a-5371f93062af188d7c08c32d`,
                body: `{
    "Output": {
        "BagID": "8208889180603822",
        "PostCode": "1222HA",
        "HouseNumber": "64",
        "HouseAddition": "B",
        "HouseAddress": "5427HC-12-",
        "City": "Boekel",
        "Street": "Statenweg",
        "HouseType": "woonfunctie",
        "BuildYear": "1970",
        "OuterSurfaceArea": "710",
        "Longitude": "5.11111111111111",
        "Latitude": "51.6000000000000",
        "WOZ-source_date": "2021-05-31",
        "wozvalue": [
            {
                "Date": "01-01-2020",
                "Value": "710777"
            },
            {
                "Date": "01-01-2019",
                    Value": "548170"
            },
            {
                "Date": "01-01-2018",
                "Value": "547133"
            },
            {
                "Date": "01-01-2017",
                "Value": "324704"
            },
            {
                "Date": "01-01-2016",
                "Value": "435205"
            },
            {
                "Date": "01-01-2015",
                "Value": "271321"
            },
            {
                "Date": "01-01-2014",
                "Value": "820148"
            }
        ]
    }
}
                `
            }
        },
        get: {
            request: {
                query: `https://api.altum.ai/sandbox/woz?bag_id=0402010001567022`,
                headers: `Content-Type: application/json
x-api-key: m2ipzWVV3e9yPU9TduqpY4oZTbcEHCGj31GLVLYB`
            },
            response: {
                headers: `access-control-allow-origin: *
content-length: 614
content-type: application/json
date: Mon, 26 Jul 2021 21:20:24 GMT
x-amz-apigw-id: DGML4FiJDoEFnSA=
x-amzn-requestid: fac8040f-b757-4cda-8d1c-c29c2c4916f2
x-amzn-trace-id: Root=1-60ff2718-3b2ead0f4d0c5e311f3340c1
                `,
                body: `{
    "Output": {
        "BagID": "0402010001567022",
        "PostCode": "1111ZZ",
        "HouseNumber": "10",
        "HouseAddition": "null",
        "HouseAddress": "1111ZZ-10-",
        "City": "Boekel",
        "Street": "Statenweg",
        "HouseType": "woonfunctie",
        "BuildYear": "1970",
        "OuterSurfaceArea": "710",
        "Longitude": "5.11111111111111",
        "Latitude": "51.6000000000000",
        "WOZ-source_date": "2021-05-31",
        "wozvalue": [
            {
                "Date": "01-01-2020",
                "Value": "581506"
            },
            {
                "Date": "01-01-2019",
                "Value": "397034"
            },
            {
                "Date": "01-01-2018",
                "Value": "853726"
            },
            {
                "Date": "01-01-2017",
                "Value": "826922"
            },
            {
                "Date": "01-01-2016",
                "Value": "886513"
            },
            {
                "Date": "01-01-2015",
                "Value": "436488"
            },
            {
                "Date": "01-01-2014",
                "Value": "176526"
            }
        ]
    }
}`
            }
        }
    }
}
export const autosuggest = {
    name: "Auto suggest API",
    docsL :'https://docs.altum.ai/apis/auto-suggest-api',
    description: "Het doel van deze API is om automatisch adressen voor te stellen en aan te vullen in een applicatie. Dit heeft voordelen bij het selecteren van de juiste woning, zorgt voor een aangenamere gebruikerservaring voor de gebruiker en verbetert de klantreis in een applicatie.",
    link: "/sandbox/autosuggest",
    methods: ["GET"],
    example: {
        get: {
            request:{
                query: `https://api.altum.ai/sandbox/autosuggest?search=bellefleurho5&fuzzy=1`,
                headers: `Content-Type: application/json
x-api-key: m2ipzWVV3e9yPU9TduqpY4oZTbcEHCGj31GLVLYB`
            },
            response:{

            }
        }
    }
}
export const moveData = {
    name: "Verhuisdata API",
    docs: 'https://docs.altum.ai/apis/move-data',
    description:
        `De Verhuisdata API geeft inzicht in woningen en hun status op de markt, inclusief de marktgegevens op postcodeniveau.
    Met de Verhuisdata API kun je: de marktgegevens van een object op basis van het adres ophalen, 
    gegevens ophalen over vijf relevante woningen in eigendom of verhuur in een bepaalde postcode, 
    het totale aantal vermelde woningen ophalen te huur zowel als te koop in een bepaalde postcode.`,
    link: "/sandbox/movedata",
    methods: ["POST", "GET"],
    example: {
        get: {
            request:{
                query: `https://api.altum.ai/movedata/sandbox/rent?search=5104
OR https://api.altum.ai/movedata/sandbox/sale?search=4841ES
OR https://api.altum.ai/movedata/sandbox/count?search=4841ES`,
                headers: `Content-Type: application/json
x-api-key: m2ipzWVV3e9yPU9TduqpY4oZTbcEHCGj31GLVLYB`
            },
            response:{
                headers: `content-length: 2552
content-type: application/json
date: Mon, 26 Jul 2021 22:00:13 GMT
x-amz-apigw-id: DGSBHGOrjoEFr8Q=
x-amzn-requestid: 970e07cb-e0b3-4e37-9f41-33dfc02c5de6
x-amzn-trace-id: Root=1-60ff306d-3c6f27c2756f1f0f0e857f48`,
                body: `{
    "ForRent": {
        "1": {
            "PostCode": "4301HS",
            "HouseNumber": 21,
            "HouseAddition": "null",
            "City": "Zierikzee",
            "Street": "Steiltjesstraat",
            "HouseType": "2-onder-1-kapwoning",
            "BuildingType": "Eengezinswoning, 2-onder-1-kapwoning",
            "BuildingPeriod": "null",
            "BuildYear": 1977,
            "InnerSurfaceArea": "130 m²",
            "OuterSurfaceArea": "null",
            "Volume": "350 m³",
            "ParcelSize": "150 m²",
            "EnergyLabel": "",
            "ProvisionalEnergyLabel": "C",
            "AskingPriceSale": "Laatste vraagprijs",
            "AskingPriceRent": "",
            "ListedSince": "",
            "SourceURL": "https://www.funda.nl/huur/verhuurd/zierikzee/huis-41158211-steiltjesstraat-21/"
        },
        "2": {
            "PostCode": "4301ZC",
            "HouseNumber": 2,
            "HouseAddition": "null",
            "City": "Zierikzee",
            "Street": "Zeesterpad",
            "HouseType": "hoekwoning",
            "BuildingType": "Eengezinswoning, hoekwoning",
            "BuildingPeriod": "null",
            "BuildYear": 1979,
            "InnerSurfaceArea": "104 m²",
            "OuterSurfaceArea": "null",
            "Volume": "330 m³",
            "ParcelSize": "",
            "EnergyLabel": "E",
            "ProvisionalEnergyLabel": "",
            "AskingPriceSale": "Laatste vraagprijs",
            "AskingPriceRent": "",
            "ListedSince": "",
            "SourceURL": "https://www.funda.nl/huur/verhuurd/zierikzee/huis-41364191-zeesterpad-2/"
        },
        "3": {
            "PostCode": "4301ZW",
            "HouseNumber": 31,
            "HouseAddition": "null",
            "City": "Zierikzee",
            "Street": "Schouwenbank",
            "HouseType": "",
            "BuildingType": "",
            "BuildingPeriod": "null",
            "BuildYear": "",
            "InnerSurfaceArea": "",
            "OuterSurfaceArea": "null",
            "Volume": "",
            "ParcelSize": "",
            "EnergyLabel": "",
            "ProvisionalEnergyLabel": "",
            "AskingPriceSale": "Laatste vraagprijs",
            "AskingPriceRent": "",
            "ListedSince": "",
            "SourceURL": "https://www.funda.nl/huur/verhuurd/zierikzee/appartement-87565011-schouwenbank-31/ "
        },
        "4": {
            "PostCode": "4301JC",
            "HouseNumber": 2,
            "HouseAddition": "null",
            "City": "Zierikzee",
            "Street": "Mol",
            "HouseType": "",
            "BuildingType": "",
            "BuildingPeriod": "null",
            "BuildYear": 1750,
            "InnerSurfaceArea": "103 m²",
            "OuterSurfaceArea": "null",
            "Volume": "267 m³",
            "ParcelSize": "",
            "EnergyLabel": "Niet beschikbaar",
            "ProvisionalEnergyLabel": "",
            "AskingPriceSale": "Laatste vraagprijs",
            "AskingPriceRent": "€ 1.200 per maand (geen servicekosten)",
            "ListedSince": "2 weken",
            "SourceURL": "https://www.funda.nl/huur/zierikzee/appartement-87726010-mol-2/"
        },
        "5": {
            "PostCode": "4301AG",
            "HouseNumber": 5,
            "HouseAddition": "null",
            "City": "Zierikzee",
            "Street": "Fonteine",
            "HouseType": "",
            "BuildingType": "",
            "BuildingPeriod": "null",
            "BuildYear": "",
            "InnerSurfaceArea": "",
            "OuterSurfaceArea": "null",
            "Volume": "",
            "ParcelSize": "",
            "EnergyLabel": "",
            "ProvisionalEnergyLabel": "",
            "AskingPriceSale": "Laatste vraagprijs",
            "AskingPriceRent": "",
            "ListedSince": "",
            "SourceURL": "https://www.funda.nl/huur/verhuurd/zierikzee/huis-87621805-fonteine-5/"
        }
    }
}`

            }
        },
        post: {
            request:{
                body: `{
    "postcode":"3038VX",
    "housenumber": 26,
    "addition": "A01"
}`,
                headers: `Content-Type: application/json
x-api-key: m2ipzWVV3e9yPU9TduqpY4oZTbcEHCGj31GLVLYB`
            },
            response:{
                headers: `access-control-allow-origin: *
content-length: 1907
content-type: application/json
date: Mon, 26 Jul 2021 21:58:25 GMT
x-amz-apigw-id: DGRwVFcSDoEFsWQ=
x-amzn-requestid: 901ddadb-6cc0-4772-a3aa-8640b9af3724
x-amzn-trace-id: Root=1-60ff3001-45c2b7ad5ef1252353f67d59`,
                body: `{
    "Output": {
        "BagID": "5937641753106108",
        "PostCode": "3038VX",
        "HouseNumber": "26",
        "HouseAddition": "26",
        "City": "Handel Handel",
        "Street": "O.-L.-Vrouwestraat",
        "HouseType": "2-onder-1-kapwoning",
        "ObjectStatus": "K",
        "BuildingType": "Eengezinswoning, 2-onder-1-kapwoning",
        "BuildingPeriod": "null",
        "BuildYear": "1940",
        "RoofType": "Zadeldak bedekt met pannen",
        "InnerSurfaceArea": "118 m²",
        "OuterSurfaceArea": "22 m²",
        "Volume": "452 m³",
        "ParcelSize": "823 m²",
        "Longitude": "5.7111111111111",
        "Latitude": "51.500000000000",
        "EnergyLabel": "null",
        "ProvisionalEnergyLabel": "G",
        "AskingPriceSale": "€ 325.000 kosten koper",
        "AskingPriceRent": "Overdracht-Laatste huurprijs",
        "Status": "Beschikbaar",
        "ListedSince": "juni 2, 2021",
        "RoomsAndBedrooms": "4 kamers (2 slaapkamers)",
        "NumberOfFloors": "2 woonlagen",
        "Floor": "null",
        "NumberOfBathroomsAndSeperateToilet": "2 badkamers en 1 apart toilet",
        "EnergyInsulation": "Gedeeltelijk dubbel glas",
        "Heating": "Cv-ketel",
        "WaterHeating": "Cv-ketel",
        "BoilerType": "Remeha (gas gestookt, eigendom)",
        "LocationType": "Aan bosrand, aan rustige weg, in woonwijk en vrij uitzicht",
        "GardenType": "Achtertuin, voortuin en zijtuin",
        "RoofTerraceAndBalcony": "null",
        "BackyardSize": "625 m² (50 meter diep en 12,5 meter breed)",
        "SunTerraceSize": "43 m²",
        "FrontYardSize": "411 m²",
        "StorageType": "null",
        "StorageRoomFacilities": "null",
        "ParkingType": "Op eigen terrein",
        "GarageType": "null",
        "GarageCapacity": "null",
        "Furnishing": "Dubbele bewoning aanwezig",
        "Apartment": "No",
        "ApartmentType": "null",
        "BuildingFacilities": "TV kabel",
        "AskingPricePerM2": "€ 2.754",
        "GardenOrientation": "Gelegen op het zuidoosten bereikbaar via achterom",
        "NeighbourhoodCode": "BU16520100",
        "NeighbourhoodName": "Handel",
        "MunicipalityCode": "1652",
        "MunicipalityName": "null",
        "SourceURL": "https://www.funda.nl/koop/handel/huis-41339981-o-l-3038VX-26/",
        "RealtorName": "Broeckx makelaars",
        "RealtorURL": "https://funda.nl/makelaars/gemert/14260-broeckx-makelaars/"
    }
}`
            }
        }
    }

}
export const location = {
    name: "Omgevingsdata API",
    docs: 'https://docs.altum.ai/apis/location-data-api',
    description: "De Omgevingsdata API levert geaggregeerde gegevens op postcodeniveau. De API bevat gegevens over bijvoorbeeld de bewoners, woningtypes en de vastgoedmarkt. Voor de buurt worden gegevens weergegeven over het totaal aantal inwoners, de leeftijdsverdeling en de samenstelling van het huishouden. Op straatniveau (postcode 6) retourneert de API de soorten woningen, bevolkingsdichtheid per vierkante kilometer, de gemiddelde verkoopduur, de gemiddelde vraagprijs en de percentages huizen in eigendom vs. verhuurd.",
    link: "/sandbox/location",
    methods: ["GET"],
    example: {
        get: {
            request: {
                query: `https://api.altum.ai/sandbox/location?postcode=1234AB`,
                headers: `Content-Type: application/json
x-api-key: m2ipzWVV3e9yPU9TduqpY4oZTbcEHCGj31GLVLYB`
            },
            response:  {
                body: `{
    "Output": {
        "Province": "Holland",
        "Municipality": "Rotterdam",
        "City": "Rotterdam",
        "NeighborhoodCode": "BU05990324",
        "NeighborhoodName": "",
        "NeighborhoodData": {
            "Inhabitants": {
                "Total": "19525.0",
                "Men": "9815.0",
                "Women": "9710.0"
            },
            "Age": {
                "0-14": "3225.0",
                "15-24": "3180.0",
                "25-44": "6620.0",
                "45-64": "4555.0",
                "65+": "1950.0"
            },
            "HouseholdComposition": {
                "Total": "9700.0",
                "Single": "4920.0",
                "WithoutKids": "1870.0",
                "WithKids": "2915.0",
                "AverageHouseholdSize": "2.0"
            }
        },
        "Postcode": "1234AB",
        "PostcodeData": {
            "HouseTypes": {
                "Corner": "",
                "Detached": "",
                "Terraced": "",
                "SemiDetached": "",
                "Linked": "",
                "LinkedSemiDetached": "",
                "End": "",
                "SingleFamily": "",
                "Gallery": "",
                "PorchFlat": "",
                "PorchHouse": "",
                "Corridor": "",
                "Maisonnette": "",
                "Downstairs": "",
                "Upstairs": "",
                "MultiFamily": ""
            },
            "PopulationDensitykm2": "15769",
            "AverageForSaleDuration": "28.0",
            "AveragePrice": "300000.0",
            "PercentHousesRented": "0.0",
            "PercentHousesOwned": "100.0",
            "Foundation": {
                "PhysicalRegion": {
                    "Description": "Niet indeelbaar",
                    "Short": "ni",
                    "Translation": "Not classifiable"
                },
                "Ground": {
                    "Description": "Overig",
                    "Code": "41",
                    "Translation": "Other"
                }
            }
        }
    }
}`,
                headers: `access-control-allow-origin: *
content-length: 1063
content-type: application/json
date: Mon, 26 Jul 2021 22:04:33 GMT
x-amz-apigw-id: DGSpxE01DoEFb1A=
x-amzn-requestid: 1263a918-b691-4211-9e91-fe7839279e82
x-amzn-trace-id: Root=1-60ff3171-671cb48142717cf45ccd4e86`
            }
        }
    }
}
export const amenities = {
    name: "Voorzieningen API",
    docs: 'https://docs.altum.ai/apis/amenities-api',
    description: "De Voorzieningen API retourneert de afstand tot voorzieningen voor een bepaald adres in meters voor voorzieningen zoals een school, het treinstation en een bushalte. Met dit eindpunt kunt u de afstand tot meerdere voorzieningen voor een enkel object bepalen.",
    link: "/sandbox/amenities",
    methods: ["POST"],
    example:{
        post: {
            request: {
                headers:  `Content-Type: application/json
x-api-key: m2ipzWVV3e9yPU9TduqpY4oZTbcEHCGj31GLVLYB`,
                body:  `{
    "postcode":"3038VX",
    "housenumber": 26,
    "addition": "A01"
}`
            },
            response: {
                headers: `access-control-allow-origin: *
content-length: 248
content-type: application/json
date: Mon, 26 Jul 2021 22:15:25 GMT
x-amz-apigw-id: DGUPkE3iDoEFg9Q=
x-amzn-requestid: a460d634-5272-4761-b4a5-29087a31b566
x-amzn-trace-id: Root=1-60ff33fd-7a629140019aeb3e539e91f8`,
                body: `{
    "Output": {
        "PostCode": "3038VX",
        "HouseNumber": "26",
        "HouseAddition": null,
        "Amenities": {
            "RailwayStation": "628",
            "School": "296",
            "ShoppingMall": "1380",
            "HighwayEntry": "416",
            "PoliceStation": "2159",
            "ConvenienceStore": "338",
            "BusStop": "627",
            "Hospital": "736"
        }
    }
}`
            }
        }
    }
}
// export const RealEstate = {
//     name: "Real Estate API",
//     description: "Short escription of this API.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
//     link: "https://api.altum.ai/sandbox/listing",
//     methods: ["POST"],
// }
// export const image = {
//     name: "Image API",
//     description: "Short escription of this API.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
//     link: "https://api.altum.ai/sandbox/image/label",
//     methods: ["POST"],
// }