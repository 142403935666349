import React, { useState, useEffect } from 'react'
import './ApiPage.css'
import { InputGroup, FormControl, Button, Form, FormGroup, DropdownButton, Dropdown, Tabs, Tab, TabContainer, FloatingLabel, CardGroup, Card } from 'react-bootstrap'
import KeyValueField from './keyValueField';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
const arrToObject = (arr) => {
    var result = {};
    for (var i = 0; i < arr.length; i++) {
        result[arr[i].key] = arr[i].value;
    }
    return result;
}
const ApiPage = ({ api }) => {
    const [requestData, setRequestData] = useState({});
    const [bulkInput, setBulkInput] = useState(true);
    const [response, setResponse] = useState();
    //when going to new route and passing new api - set endpoint and method
    useEffect(() => {
        setRequestData(prev => {
            return {
                ...prev,
                body: '',
                path: api?.link,
                method: api?.methods[0]
            }
        })
        setResponse();
    }, [api])
    const handleChange = (e) => {
        setRequestData((prev) => { return { ...prev, [e.target.name]: e.target.value } })
    }
    const handleBulkChange = (e) => {
        setRequestData(prev => { return { ...prev, body: e.target.value } });
    }
    const handleSelectChange = (e) => {
        setRequestData((prev) => { return { ...prev, method: e.target.value } })
    }
    const getHeadersData = (data) => {
        setRequestData((prev) => { return { ...prev, headers: data } })
    }
    const getBodyData = (data) => {
        setRequestData((prev) => { return { ...prev, body: data } })
    }
    const getResponseCode = (code) => {
        let res;
        switch (code) {
            case 200:
                res = 'OK';
                break;
            case 400:
                res = 'Bad Request';
                break;
            case 403:
                res = 'Forbidden';
                break;
            case 422:
                res = 'Validation Error';
                break;
            case 429:
                res = 'Limit exceeded';
                break;
            case 500:
                res = 'Service Not Available';
                break;
            default:
                res = 'Unknown responce';
                break;
        }
        return res;
    }
    const handleSubmit = () => {
        let url;
        let body;
        url = requestData.path;
        { bulkInput ? body = requestData.body : body = arrToObject(requestData.body) };
        const options = {
            method: requestData.method,
            url: url,
            headers: arrToObject(requestData.headers),
            data: body
        };
        // axios.defaults.withCredentials = true;
        axios.request(options).then(function (response) {

            setResponse({
                status: response.status,
                responseData: response.data,
                headers: response.headers,
                size: response.headers['content-length']
            })
        }).catch(function (error) {
            setResponse({
                status: error.response.status,
                responseData: error.response.data,
                headers: error.response.headers,
                size: error.response.headers['content-length']
            })
        });
    }

    return (
        <div className="api-page">
            <div className="api-page-container p-4">
                <div className="api-description">
                    <h2 className="api-name">{api?.name}</h2>
                    <p>{api?.description} <a href={api?.docs} className="docs-link">Documentatie <FontAwesomeIcon icon={faExternalLinkAlt} /></a></p>
                </div>
                <div className="api-request-form">

                    <Form>
                        <FormGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <FormControl
                                        as="select"
                                        variant="outline-secondary"
                                        id="method-select"
                                        onChange={handleSelectChange}
                                    >
                                        {api?.methods.map((method, key) => <option key={key}>{method}</option>)}
                                    </FormControl>
                                </InputGroup.Prepend>

                                <FormControl
                                    placeholder="Api endpoint"
                                    value={requestData?.path}
                                    name="path"
                                    onChange={handleChange}
                                />
                                <InputGroup.Append>
                                    <Button variant="success" onClick={handleSubmit}>Versturen</Button>
                                </InputGroup.Append>
                            </InputGroup>

                            <Tabs
                                // defaultActiveKey={requestData.method == "POST" ? 'body' : 'headers'}
                                transition={false}
                            >
                                {requestData.method !== "GET" &&
                                    <Tab
                                        eventKey="body"
                                        title="API-verzoek"
                                        className="border"
                                        style={{ position: 'relative' }}>
                                        <Button variant="outline-dark" size="sm" id="bulk-btn" onClick={() => setBulkInput(!bulkInput)}>{bulkInput ? 'Bewerken' : 'Bulkbewerking'}</Button>
                                        {bulkInput && <>

                                            <Form.Control
                                                as="textarea"
                                                value={requestData?.body}
                                                placeholder='{"postcode": "1234AB", "housenumber": "1"}'
                                                style={{ height: '200px', border: '0' }}
                                                onChange={handleBulkChange}
                                            />

                                        </>}
                                        {!bulkInput && <KeyValueField body="true" getData={getBodyData} />}
                                    </Tab>
                                }
                                <Tab
                                    eventKey="headers"
                                    title="Headers"
                                    className="border">
                                    <KeyValueField header="true" getData={getHeadersData} />
                                </Tab>
                            </Tabs>

                        </FormGroup>
                    </Form>
                </div>
                {response &&
                    <div className="api-response">
                        <div className="api-response__status">
                            {response?.status} {response?.status && getResponseCode(response?.status)} <span style={{ marginLeft: '2rem' }}></span>{response?.size && `${response?.size} B`}
                        </div>
                        <Tabs
                            defaultActiveKey="response-body">
                            <Tab
                                eventKey="response-body"
                                title="API respons"
                                className="border">
                                <div className="api-response__container">
                                    <pre>{response?.responseData && JSON.stringify(response.responseData, null, "\t")}</pre>
                                </div>
                            </Tab>
                            <Tab
                                eventKey="responseheaders"
                                title="Headers"
                                className="border">
                                <div className="api-response__container">
                                    {response?.headers && Object.keys(response?.headers).map(key => <p>{key}: {response.headers[key]}</p>)}
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                }

                <div className='api-request-form'>
                    

                    <Tabs
                        defaultActiveKey="example-request">
                        <Tab
                            eventKey="example-request"
                            title="Voorbeeld aanvragen"
                            className="border">
                            <CardGroup>
                                <Card>
                                    <Card.Header>
                                        API-verzoek
                                    </Card.Header>
                                    <Card.Body>
                                        {requestData?.method === "POST" &&
                                            <pre>{api?.example?.post?.request?.body}</pre>}
                                        {requestData?.method === "GET" &&
                                            <pre>{api?.example?.get?.request?.query}</pre>}

                                    </Card.Body>
                                </Card>
                                <Card>

                                    <Card.Header>
                                        Headers
                                    </Card.Header>
                                    <Card.Body>
                                        {requestData?.method === "POST" &&
                                            <pre>{api?.example?.post?.request?.headers}</pre>}
                                        {requestData?.method === "GET" &&
                                            <pre>{api?.example?.get?.request?.headers}</pre>}
                                    </Card.Body>
                                </Card>
                            </CardGroup>
                        </Tab>
                        <Tab
                            eventKey="example-response"
                            title="API reactie voorbeeld"
                            className="border">
                            <CardGroup>
                                <Card>
                                    <Card.Header>
                                        Headers
                                    </Card.Header>
                                    <Card.Body>
                                        {requestData?.method === "POST" && <pre>{api?.example?.post?.response?.headers}</pre>}
                                        {requestData?.method === "GET" && <pre>{api?.example?.get?.response?.headers}</pre>}
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        API respons
                                    </Card.Header>
                                    <Card.Body>
                                        {requestData?.method === "POST" &&
                                            <pre>
                                                {api?.example?.post?.response?.body}
                                            </pre>
                                        }
                                        {requestData?.method === "GET" &&
                                            <pre>
                                                {api?.example?.get?.response?.body}
                                            </pre>
                                        }

                                    </Card.Body>
                                </Card>
                            </CardGroup>
                        </Tab>
                    </Tabs>

                </div>
            </div>
        </div>
    )
}

export default ApiPage
