import React from 'react'

const Landing = () => {
    return (
        <div className="landing api-page">
            <div className="api-page-container p-4">
                <h1 className="intro">Introductie</h1>
                <p className="intro">Welkom bij de Sandbox-omgeving van Altum AI's API's. Altum AI heeft een Sandbox-endpoint om een ​​creditvriendelijke omgeving te hebben om API-integraties te testen.</p>
                <p className="intro">Om de API's in Sandbox-modus te gebruiken, voegt u eenvoudig <em>/sandbox/</em> toe aan het URL-pad van het eindpunt en voert u een POST- of GET-verzoek uit, afhankelijk van de documentatie van de specifieke API.</p>
                <p className="intro">Gebruik API-key <strong>m2ipzWVV3e9yPU9TduqpY4oZTbcEHCGj31GLVLYB</strong> om verzoeken aan de Sandbox-API uit te voeren.</p>
            </div>

        </div>
    )
}

export default Landing
