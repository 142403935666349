import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router, Route,
} from "react-router-dom";
import Header from './Components/Header/Header';
import Main from './Components/Main/Main'

function App() {
  return (
      <div className="App">
      <Router>
        <div>
        <Header/>
        <Route path="/" component={Main}/>
        </div>
      </Router>
      </div>
  );
}

export default App;
