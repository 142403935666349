import React, { useState, useEffect } from 'react'
import { InputGroup, FormControl, Button } from "react-bootstrap"
import { Prev } from 'react-bootstrap/esm/PageItem';

export const Field = ({ tkey, value, onDelete, id, onSubmit }) => {
    const [keyValue, setKeyValue] = useState({ key: tkey, value: value });
    const handleDelete = () => { return onDelete(id); }
    const handleChange = (e) => { setKeyValue(prev => { return { ...prev, [e.target.name]: e.target.value } }); }
    const submitField = () => { return onSubmit(id, keyValue); }
    return (
        <div className="key-value__field">
            <InputGroup >
                <FormControl
                    placeholder={keyValue?.key}
                    aria-label="Value"
                    aria-describedby="basic-addon2"
                    name="key"
                    value={keyValue?.key}
                    onChange={handleChange}
                    onBlur={submitField}
                />
                <FormControl
                    placeholder={keyValue.value}
                    aria-label="Value"
                    name="value"
                    aria-describedby="basic-addon2"
                    value={keyValue?.value}
                    onChange={handleChange}
                    onBlur={submitField}
                />
                <button className="key-value__remove" onClick={handleDelete}>x</button>
            </InputGroup>
        </div>
    )
}

const KeyValueField = ({ header, body, getData }) => {
    const [fields, setFields] = useState([]);
    useEffect(() => {
        if (header)
            setFields([
                { key: "Content-type", value: "application/json" },
                { key: "x-api-key", value: "m2ipzWVV3e9yPU9TduqpY4oZTbcEHCGj31GLVLYB" }
            ]);
        else if (body)
            setFields([
                { key: "", value: "" }
            ]);
        else
            setFields([{}]);
    }, []);

    useEffect(() => { return getData(fields); }, [fields])

    const addField = () => {
        let newField = {};
        setFields(prev => [...prev, newField]);
    }
    const addFieldContent = (id, data) => {
        const newFields = [...fields];
        newFields[id] = data;
        setFields(newFields);
    }
    const deleteField = (id) => { 
        
        setFields(fields.filter((field, index) => { return index !== id; })); 
    }

    return (
        <div className="key-value">
            <div className="key-value__header">
                <div className="header-word">Key</div>
                <div className="header-word">Value</div>
            </div>
            <div className="key-value__fields">
                {fields?.map((field, index) => <Field id={index} key={index} tkey={field.key} value={field.value} onDelete={deleteField} onSubmit={addFieldContent} />)}
            </div>
            <Button variant="success" onClick={addField} className="key-value__add">+</Button>
        </div>
    )
}

export default KeyValueField
