import React from 'react'
import { BrowserRouter as Router, Link} from "react-router-dom"
import {Form} from 'react-bootstrap'
import "./Header.css"
import altumLogo from '../../imgs/altum-logo.png'
const Header = () => {
    return (
        <div className="header">
            <div className="header_container">
                <div className="header_logo">
                <div className="header_link">
                <Link to="/">
                    <img src={altumLogo}></img>
                    
                    Sandbox
                    </Link>
                </div>
                </div>
                {/* <div className="header-search">
                    <Form>
                        <Form.Control type="text" placeholder="Search..."></Form.Control>
                    </Form>
                </div> */}
            </div>
        </div>
    )
}

export default Header
