import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import './Main.css'
import { amenities, AVM, Eco,location, moveData, woz, autosuggest } from './apiData'
import ApiPage from '../ApiPage/ApiPage'
import {Switch, Route} from 'react-router-dom'
import Landing from './Landing'
const Main = () => {
    return (
        <div className="main">
            <div className="main_container">
                <Sidebar />
                <Switch>
                     <Route exact path="/">
                       <Landing/>
                    </Route>
                    <Route path="/ecovalue">
                       <ApiPage api={Eco}/>
                    </Route>
                    <Route path="/avm">
                       <ApiPage api={AVM}/>
                    </Route>
                    <Route path="/woz">
                       <ApiPage api={woz}/>
                    </Route>
                    <Route path="/movedata">
                       <ApiPage api={moveData}/>
                    </Route>
                    <Route path="/location">
                       <ApiPage api={location}/>
                    </Route>
                    <Route path="/amenities">
                       <ApiPage api={amenities}/>
                    </Route>
                    <Route path="/autosuggest">
                       <ApiPage api={autosuggest}/>
                    </Route>
                    {/* <Route path="/image">
                       <ApiPage api={image}/>
                    </Route> */}
                </Switch>
            </div>
        </div>
    )
}

export default Main
