import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'
import './Sidebar.css'

const Sidebar = () => {
    return (
        <div className="sidebar">
            <div className="sidebar_container">
                <ul className="sidebar_nav">
                    <li className="nav-header">API's Sandbox</li>
                    <ul className="api-list">
                        <li className="api-link"><Link to="/ecovalue">Verduurzamings API</Link></li>
                        <li className="api-link"><Link to="/avm">Woningwaarde API</Link></li>
                        <li className="api-link"><Link to="/woz">WOZ waarde API</Link></li>
                        <li className="api-link"><Link to="/movedata">Verhuisdata API</Link></li>
                        <li className="api-link"><Link to="/location">Omgevingsdata API</Link></li>
                        <li className="api-link"><Link to="/amenities">Voorzieningen API</Link></li>
                        {/* <li className="api-link"><Link to="/autosuggest">Auto Suggest API</Link></li> */}
                        {/* <li className="api-link"><Link to="/realestate">Real Estate listing API</Link></li>
                        <li className="api-link"><Link to="/image">Image API</Link></li> */}
                    </ul>
                    <li className="nav-header"><a href="https://docs.altum.ai/">Documentatie</a><FontAwesomeIcon icon={faExternalLinkAlt}/></li>
                    <li className="nav-header"><a href="https://mopsus.altum.ai/">Mopsus Data Platform</a><FontAwesomeIcon icon={faExternalLinkAlt}/></li>
                    <li className="nav-header"><a href="https://altum.ai/">Altum AI website</a><FontAwesomeIcon icon={faExternalLinkAlt}/></li>
                    <li className="nav-header"><a href="https://altum.ai/pricing/">Pricing</a><FontAwesomeIcon icon={faExternalLinkAlt}/></li>
                    <li className="nav-header"><a href="https://altum.ai/contact/">Contact</a><FontAwesomeIcon icon={faExternalLinkAlt}/></li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar

